import {
  Drawer, DrawerProps, Separator, Stack,
} from '@lualtek/react-components';
import { FC, Fragment, useRef } from 'react';
import { RovingTabIndexProvider } from 'react-roving-tabindex';

import { useNotificationsEvents } from '@/api/notifications';
import { Notification } from '@/components/common/shared/notification';
import { useScrollListener } from '@/core/helpers/use-scroll-listener';
import { useTranslate } from '@/core/i18n';

import { useNotificationRender } from '../notification/use-notification-render';

export const EventsDrawer: FC<DrawerProps> = ({ isOpen, onClose, ...otherProps }) => {
  const { t } = useTranslate();
  const { events, fetchMoreEvents } = useNotificationsEvents();
  const { generateEventProps } = useNotificationRender();
  const refScrollerElement = useRef<HTMLDivElement>(null);

  useScrollListener(refScrollerElement, async () => fetchMoreEvents());

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      title={t('common:notifications.eventsDrawer.title')}
      maxWidth="578px"
      side="left"
      scrollerRef={refScrollerElement}
      {...otherProps}
    >
      <Stack vAlign="start" fill={false}>
        <RovingTabIndexProvider options={{ direction: 'vertical' }}>
          {events.map((notification, index) => (
            <Fragment key={notification.id}>
              <Notification
                {...generateEventProps(notification)}
              />
              {index < events.length - 1 ? <Separator hPadding={24} /> : null}
            </Fragment>
          ))}
        </RovingTabIndexProvider>
      </Stack>
    </Drawer>
  );
};
