import { useRouter } from 'next/router';
import { useCallback } from 'react';

export const useCurrentQuery = () => {
  const router = useRouter();

  const getRouterNext = useCallback(
    () => `?next=${(router.query.next as string | undefined) ?? router.asPath}`,
    [router.asPath, router.query.next],
  );

  // All possible query params
  return {
    analysisslug: router.query.analysisslug as string,
    analysisid: Number(router.query.analysisid as string),
    analysisactiveid: Number(router.query.analysisactiveid as string),
    appid: Number(router.query.appid as string),
    deviceid: Number(router.query.deviceid as string),
    automationId: Number(router.query.automationid as string),
    areaid: Number(router.query.areaid as string),
    next: router.query.next as string || '/',
    gotoscene: Number(router.query.gotoscene as string) || undefined,
    getRouterNext,
  };
};
