// const dynamicUseMouse = dynamic(async () => import('ahooks').then(mod => mod.useMouse), { ssr: false });
import { Panel, Stack, useResponsiveContext } from '@lualtek/react-components';
import { TokensTypes } from '@lualtek/tokens/platforms/web';
import clsx from 'clsx';
import dynamic from 'next/dynamic';
import React, {
  useRef,
} from 'react';

import { ThemeSwitcherProps } from '@/components/common/desktop/theme-switcher/theme-switcher';
import { BaseLayout } from '@/components/layouts/shared/base-layout';
import { useMobileContext } from '@/context/use-mobile-context';

import { GateLayoutType } from '../../types';
import styles from './gate-layout.module.css';

const DynThemeSwitcher = dynamic<ThemeSwitcherProps>(
  async () => import('@/components/common/desktop/theme-switcher').then(mod => mod.ThemeSwitcher),
  { ssr: false },
);

export type GateLayoutProps = {
  contentPadding?: TokensTypes['space'];
}

export const GateLayout: GateLayoutType = ({
  className,
  children,
  contentPadding = 88,
  ...otherProps
}) => {
  const ref = useRef<HTMLElement>(null);
  const { isMobile } = useMobileContext();
  const { matches } = useResponsiveContext();

  return (
    <BaseLayout>
      <main
        ref={ref}
        className={clsx(styles.GateLayout, className)}
        {...otherProps}
      >
        <Panel
          vibrant
          vibrancyLevel="soft"
          vibrancyColor="soft"
          className={styles.Panel}
          bordered={!isMobile}
        >
          <Stack
            vAlign="center"
            fill={false}
            className={styles.Frame}
            vPadding={32}
            hPadding={matches.medium ? contentPadding : 24}
            rowGap={24}
          >
            <Stack>
              {children}
            </Stack>

            <Stack hAlign="center">
              <DynThemeSwitcher side="top" />
            </Stack>
          </Stack>
        </Panel>

        <div className={styles.Animation}>
          <div className={styles.Wave} />
          <div className={styles.Wave} />
          <div className={styles.Wave} />
          <div className={styles.Wave} />
          <div className={styles.Wave} />
          <div className={styles.Wave} />
          <div className={styles.Wave} />
          <div className={styles.Wave} />
        </div>
      </main>
    </BaseLayout>
  );
};
