import { Toast, ToastViewport } from '@lualtek/react-components';
import { domMax, LazyMotion } from 'motion/react';
import useTranslation from 'next-translate/useTranslation';
import { useEffect } from 'react';

import { useFeedbackContext } from '@/context/use-feedback-context';
import { useMobileContext } from '@/context/use-mobile-context';

import { CommonLayoutType } from '../../types';
import styles from './base-layout.module.css';

export const BaseLayout: CommonLayoutType = ({ children }) => {
  const { feedbacks, resetFeedbacks } = useFeedbackContext();
  const { isMobile } = useMobileContext();
  const { t } = useTranslation();

  // Reset feedback when changing layout
  useEffect(() => () => resetFeedbacks(), [resetFeedbacks]);

  return (
    <>
      <LazyMotion features={domMax}>
        {children}
      </LazyMotion>

      {feedbacks.map(({ id, ...rest }) => (
        <Toast
          key={id}
          dismissable
          dismissLabel={t('common:cta.dismiss')}
          {...rest}
        />
      ))}

      <ToastViewport className={styles.ToastViewport} data-is-mobile={isMobile} />
    </>
  );
};

