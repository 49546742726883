import {
  Container, ContainerProps, Stack, StackProps,
} from '@lualtek/react-components';
import clsx from 'clsx';
import { m } from 'motion/react';

import { LangSwitcher } from '@/components/common/shared/lang-switcher';
import { BaseLayout } from '@/components/layouts/shared/base-layout';

import { FullLayoutType } from '../../types';
import styles from './full-layout.module.css';

export type FullLayoutProps = {
  showLanguageSwitcher?: boolean;
  width?: ContainerProps['dimension'];
  vAlign?: StackProps['vAlign'];
  title?: string;
}

export const FullLayout: FullLayoutType = ({
  className,
  children,
  width = 'small',
  vAlign = 'center',
  showLanguageSwitcher = true,
  ...otherProps
}) => (
  <BaseLayout>
    <main className={clsx(styles.FullLayout, className)} {...otherProps}>
      <Stack vAlign={vAlign} fill={false} as={Container} dimension={width} className={styles.Container}>
        <m.div
          className={styles.Shape}
          initial={{ x: -200, rotate: 30, opacity: 0 }}
          animate={{ x: 0, rotate: 60, opacity: 1 }}
          transition={{
            type: 'spring',
            stiffness: 100,
            damping: 30,
          }}
        />
        {children}
      </Stack>
      {showLanguageSwitcher && (
        <Stack hAlign="center" vPadding={24} className={styles.Footer}>
          <LangSwitcher />
        </Stack>
      )}
    </main>
  </BaseLayout>
);
